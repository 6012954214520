import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import YouTube from 'react-youtube';

export const WatchVideoModal: React.FC<{ isOpen: boolean; videoId: string; toggle: () => void; size: string }> = ({
  toggle,
  size,
  isOpen,
  videoId
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size={size} centered>
      <ModalBody>
        <div className="embed-responsive embed-responsive-16by9">
          <YouTube
            containerClassName="embed-responsive-item"
            videoId={videoId}
            opts={{
              height: '390',
              width: '640',
              playerVars: {
                autoplay: 1,
                modestbranding: 1,
                rel: 0,
                showinfo: 0,
                playsinline: 1,
                loop: 1,
                controls: 0
              }
            }}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};
