import { useRouter } from 'next/router';

const youtubePromoVideoIDs: Record<string, string> = {
  en: 'rNU3dC_1sOQ',
  de: 'efqxlR-i4HA',
  pl: 'TR___C8D61c'
};

export const useYoutubeVideoPromoID = (): string => {
  const router = useRouter();
  const locale: string = router.locale as string;
  return youtubePromoVideoIDs[locale] ?? youtubePromoVideoIDs.en;
};
