import React, { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import { useYoutubeVideoPromoID } from './hooks/useYoutubeVideoPromoID';
import { WatchVideoModal } from './WatchVideoModal';

export const WatchVideoComponentMobile: React.FC = () => {
  const intl = useIntl();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const promoVideoID = useYoutubeVideoPromoID();
  const onVideoClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setModal(true);
  }, []);

  return (
    <>
      <Button
        color="accent"
        block
        className="text-primary text-heading py-3 mb-2 btn-mobile-border d-lg-none"
        onClick={onVideoClick}
        href={`https://www.youtube.com/watch?v=${promoVideoID}`}
      >
        {intl.formatMessage({
          id: 'app.watch-video.title',
          defaultMessage: 'Watch Video'
        })}
      </Button>
      <WatchVideoModal isOpen={modal} toggle={toggle} videoId={promoVideoID} size="xl" />
    </>
  );
};
