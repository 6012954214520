import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { useIntl } from 'react-intl';
import style from './WatchVideoComponent.module.scss';
import HomeWatchVideo from '../../public/statics/svgs/home-watch-video-bg.svg';
import { useYoutubeVideoPromoID } from './hooks/useYoutubeVideoPromoID';
import { WatchVideoModal } from './WatchVideoModal';

export const WatchVideoComponent: React.FC = () => {
  const intl = useIntl();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const promoVideoID = useYoutubeVideoPromoID();
  const onVideoClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setModal(true);
  }, []);

  return (
    <div className={style.container}>
      <HomeWatchVideo />
      <a className={style.content} onClick={onVideoClick} href={`https://www.youtube.com/watch?v=${promoVideoID}`}>
        <FontAwesomeIcon icon={faPlay} className={style.icon} />
        <div className={style.title}>
          {intl.formatMessage({
            id: 'app.watch-video.title',
            defaultMessage: 'Watch Video'
          })}
        </div>
      </a>
      <WatchVideoModal isOpen={modal} toggle={toggle} videoId={promoVideoID} size="lg" />
    </div>
  );
};
